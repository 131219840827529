<template>
  <div v-if="showLink">
    <b-row class="mb-1">
      <b-col md="12">
        <b-media vertical-align="center" no-body>
          <b-media-aside class="align-items-center">
            <b-button
              @click="close()"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-secondary"
              class="btn-icon rounded-circle mr-50"
              v-b-tooltip.hover.top
              title="Back"
            >
              <feather-icon icon="ArrowLeftIcon" size="18" />
            </b-button>
            <!-- <b-avatar
              size="50"
              :src="currentItem.pic"
              variant="light-primary"
            /> -->
          </b-media-aside>

          <b-media-body>
            <b-link
              class="font-weight-bold d-block text-nowrap"
              style="margin-bottom: 4px"
            >
              <b-badge variant="light-primary" class="mr-1">
                {{ getSubject(currentItem.subjectID) }}
              </b-badge>
              <b-badge variant="light-primary">
                {{ getClass(currentItem.cID, currentItem.secID) }}
              </b-badge>
            </b-link>

            <small class="text-muted">
              <b-badge variant="light-primary">
                {{ getTeacher(currentItem.tID) }}
              </b-badge>
            </small>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>
    <b-row align-h="center" v-if="linkLoading">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        variant="primary"
        label="loading..."
      ></b-spinner>
    </b-row>
    <iframe
      v-else
      class="border-0 w-100 set-aspect"
      :src="currentItem.link"
      allow="microphone; camera"
    ></iframe>
    <!-- src="https://p2p.mirotalk.com/join/gmvcgvphgp" -->
  </div>
  <div v-else>
    <b-modal
      id="modal-meeting"
      cancel-variant="outline-secondary"
      hide-footer
      centered
      no-close-on-backdrop
      title="Live Class"
      size="lg"
    >
      <b-row class="">
        <b-col lg="3" md="6">
          <b-form-group
            label="Class"
            invalid-feedback="Class is required."
            ref="class"
          >
            <v-select
              v-model="myObj.cID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="classesOptions"
              :clearable="false"
              label="name"
              :reduce="(val) => val.id"
              :disabled="dataLoading"
              placeholder="Select class."
              @input="FillSections()"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6">
          <b-form-group
            label="Section"
            invalid-feedback="section is required."
            ref="sec"
          >
            <v-select
              v-model="myObj.secID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="currentSections"
              :reduce="(val) => val.id"
              label="section"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select section."
            />
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6">
          <b-form-group
            label="Subject"
            invalid-feedback="Subject is required."
            ref="sub"
          >
            <v-select
              v-model="myObj.subjectID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="allSubjects"
              :reduce="(val) => val.id"
              label="subject"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select subject."
            />
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6">
          <b-form-group
            label="Teacher"
            invalid-feedback="Subject is required."
            ref="teacher"
          >
            <v-select
              v-model="myObj.tID"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="staffList"
              :reduce="(opt) => opt.id"
              label="name"
              :clearable="false"
              :disabled="dataLoading"
              placeholder="Select teacher"
            />
          </b-form-group>
        </b-col>
        <b-col lg="3" md="6">
          <b-form-group
            label="Date"
            invalid-feedback="Date is required."
            ref="date"
          >
            <flat-pickr
              :config="config"
              v-model="myObj.dateTimeOfClass"
              class="form-control"
              placeholder="Select Date."
            />
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6">
          <b-form-group
            label="Link"
            invalid-feedback="Link is required."
            ref="link"
          >
            <b-form-input v-model.trim="myObj.link" placeholder="Enter link" />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row align-h="center" class="mt-1">
        <b-col md="3">
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="success"
            :disabled="request"
            @click="save()"
            block
          >
            <b-spinner v-if="request" small type="grow" />
            <span v-else class="align-middle">Save</span>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-row class="mt-1">
      <b-col xl="2" lg="3" md="4" sm="12" cols="12">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          block
          @click="AddOpen(0)"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle">Live Class</span>
        </b-button>
      </b-col>
      <!-- <b-col
        class="mt-lg-0 mt-md-0 mt-sm-1 mt-1"
        xl="10"
        lg="9"
        md="8"
        sm="12"
        cols="12"
      >
        <b-form-group class="ml-xl-2 ml-lg-2 ml-md-0 ml-sm-0 ml-0">
          <b-input-group>
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>

            <b-form-input v-model="searchQuery" placeholder="Search...">
            </b-form-input>
          </b-input-group>
        </b-form-group>
      </b-col> -->
    </b-row>

    <b-row align-h="center" v-if="dataLoading || itemLoading">
      <b-spinner
        class="m-5"
        style="width: 4rem; height: 4rem"
        type="grow"
        variant="primary"
        label="Spinning"
      ></b-spinner>
    </b-row>
    <b-row align-h="center" v-else-if="items.length == 0" style="height: 20vh">
      <h1 class="mt-1 mb-1" style="align-self: center">No records found</h1>
    </b-row>
    <b-row class="mt-2" align-h="start" v-else>
      <b-col
        v-for="(c, index) in items"
        :key="c"
        class=""
        xl="3"
        lg="4"
        md="4"
        sm="6"
        cols="12"
      >
        <b-card no-body>
          <b-card
            :bg-variant="color[index % color.length]"
            text-variant="white"
            class="mb-1"
            style="
              min-height: 150px;
              border-bottom-left-radius: 0;
              border-bottom-right-radius: 0;
            "
            body-class="p-1"
          >
            <b-card-title
              ><h1 class="mt-1 text-white">
                {{ getSubject(c.subjectID) }}
              </h1>
              <h5 class="text-white mb-1">{{ getClass(c.cID, c.secID) }}</h5>
              <h4 class="text-white">{{ getTeacher(c.tID) }}</h4>
            </b-card-title>
          </b-card>

          <b-card-title class="px-1 mb-0">
            <b
              >{{
                new Date(c.dateTimeOfClass).toLocaleDateString("en-UK", {
                  year: "numeric",
                  day: "numeric",
                  month: "short",
                })
              }}
              {{
                new Intl.DateTimeFormat("en-US", {
                  hour: "numeric",
                  minute: "numeric",
                }).format(new Date(c.dateTimeOfClass))
              }}</b
            >
          </b-card-title>

          <b-card-text class="p-1">
            <!-- <b-card-text>
              {{ c.description }}
            </b-card-text> -->

            <div class="d-flex justify-content-between">
              <b-button
                @click="openWebinar(c)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                :variant="color[index % color.length]"
                class="btn-icon"
              >
                Join Now
              </b-button>

              <div>
                <b-button
                  @click="AddOpen(c)"
                  v-b-tooltip.hover.top
                  title="Edit"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  :variant="`outline-${color[index % color.length]}`"
                  class="mr-1 btn-icon rounded-circle"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>

                <b-button
                  @click="Delete(c.id)"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-danger"
                  v-b-tooltip.hover.top
                  title="Delete"
                  class="btn-icon rounded-circle"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BContainer,
  BMediaAside,
  BMediaBody,
  BSpinner,
  BCardTitle,
  BCardText,
  BInputGroupPrepend,
  BFormInput,
  BInputGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import store from "@/store";
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from "@core/utils/filter";
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from "vue-flatpickr-component";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BImg,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BContainer,
    BMediaAside,
    BMediaBody,
    BSpinner,
    BCardTitle,
    BCardText,
    BInputGroupPrepend,
    BFormInput,
    BInputGroup,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  async created() {
    this.LoadData();
    this.LoadItems();
  },
  beforeDestroy() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
  },
  computed: {
    filters: function () {
      return this.items.filter((pro) => {
        return (
          pro.teacher.toLowerCase().match(this.searchQuery.toLowerCase()) ||
          pro.subject.toLowerCase().match(this.searchQuery.toLowerCase())
        );
      });
    },
  },
  data() {
    return {
      color: ["primary", "success", "warning", "info", "danger", "secondary"],
      rights: {},
      config: {
        enableTime: true,
        altFormat: "j M, Y h:i K",
        altInput: true,
        dateFormat: "Y-m-d\\TH:i:S",
      },
      searchQuery: "",
      dataLoading: false,
      itemLoading: false,
      items: [
        // {
        //   subject: "English",
        //   date: "2024-04-06T10:00:00",
        //   description: "Group project: Social life in the modern age",
        //   teacher: "Muhammad Ali",
        //   pic: "https://cdn.cloudious.net/file-1678094351350-637549624.png",
        // },
        // {
        //   subject: "Physics",
        //   date: "2024-04-07T11:30:00",
        //   description: "Chp#1 - Introduction to Physics",
        //   teacher: "Uzair Ahmed",
        //   pic: "https://cdn.cloudious.net/file-1678518247915-575510498.png",
        // },
        // {
        //   subject: "Mathematics",
        //   date: "2024-04-07T12:30:00",
        //   description: "Calculus",
        //   teacher: "Muhammad Athar",
        //   pic: "https://cdn.cloudious.net/file-1678464900590-606838574.jpg",
        // },
      ],
      myObj: {
        id: 0,
        cID: 0,
        secID: 0,
        subjectID: 0,
        tID: 0,
        dateTimeOfClass: null,
        link: "",
        campusID: this.$store.state.userData.cId,
      },
      currentItem: {},
      showLink: false,
      linkLoading: false,
      request: false,
      classes: [],
      classesOptions: [],
      currentSections: [],
      allSubjects: [],
      staffList: [],
    };
  },
  methods: {
    ...mapActions({ getDepartment: "getDepartment" }),
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    openWebinar(item) {
      window.open(item.link, "_blank");
      // this.currentItem = item;
      // this.linkLoading = true;
      // this.showLink = true;
      // // this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "full");
      // setTimeout(() => {
      //   this.linkLoading = false;
      // }, 1100);
    },
    close() {
      this.showLink = false;
      // this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", "boxed");
    },

    getClass(cID, id) {
      let obj = this.classes.find((el) => el.cls.id == cID);
      if (obj) {
        let sec = obj.sections.find((el) => el.id == id);
        return sec ? `${obj.cls.name} - ${sec.section}` : `${obj.cls.name}`;
      } else return "";
    },
    getSubject(id) {
      let obj = this.allSubjects.find((el) => el.id == id);
      return obj ? obj.subject : "";
    },
    getTeacher(id) {
      let obj = this.staffList.find((el) => el.id == id);
      return obj ? obj.name : "";
    },
    FillSections(text) {
      let obj = this.classes.find((el) => el.cls.id == this.myObj.cID);
      if (obj) {
        this.currentSections = obj.sections;
      } else this.currentSections = [];

      if (text != "filled") this.myObj.secID = 0;
    },
    AddOpen(id) {
      if (id == 0) {
        this.myObj = {
          id: 0,
          cID: 0,
          secID: 0,
          subjectID: 0,
          tID: 0,
          dateTimeOfClass: null,
          link: "",
          campusID: this.$store.state.userData.cId,
        };
      } else {
        this.myObj = { ...id };
        this.FillSections("filled");
      }
      this.$bvModal.show("modal-meeting");
    },

    CheckClass() {
      var elem = this.$refs["class"];
      if (this.myObj.cID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSec() {
      var elem = this.$refs["sec"];
      if (this.myObj.secID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckSub() {
      var elem = this.$refs["sub"];
      if (this.myObj.subjectID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckTeacher() {
      var elem = this.$refs["teacher"];
      if (this.myObj.tID == 0) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckDate() {
      var elem = this.$refs["date"];
      if (!this.myObj.dateTimeOfClass) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckLink() {
      var elem = this.$refs["link"];
      if (this.myObj.link == "") {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    async save() {
      this.CheckClass();
      this.CheckSec();
      this.CheckSub();
      this.CheckTeacher();
      this.CheckDate();
      this.CheckLink();
      if (
        this.CheckClass() == false ||
        this.CheckSec() == false ||
        this.CheckSub() == false ||
        this.CheckTeacher() == false ||
        this.CheckDate() == false ||
        this.CheckLink() == false
      ) {
        this.$bvToast.toast("Please fill the form correctly.", {
          title: "Error!",
          variant: "danger",
          toaster: "b-toaster-top-center",
        });
      } else {
        console.log(this.myObj);
        if (this.myObj.id == 0) {
          this.request = true;
          var status = await this.post({
            url:
              this.$store.state.domain +
              "liveClasses?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Live Class added successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;

          if (status) {
            this.LoadItems();
            this.$bvModal.hide("modal-meeting");
          }
        } else {
          this.request = true;
          var status = await this.put({
            url:
              this.$store.state.domain +
              "liveClasses/" +
              this.myObj.id +
              "?db=" +
              this.$store.state.userData.db,
            body: this.myObj,
            message: "Live Class updated successfully.",
            context: this,
            token: this.$store.state.userData.token,
          });
          this.request = false;
          if (status) {
            this.LoadItems();
            this.$bvModal.hide("modal-meeting");
          }
        }
      }
    },
    async Delete(id) {
      let result = await Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#3085d6",
        confirmButtonText: "Yes, Delete it!",
      });

      if (result.isConfirmed) {
        var status = await this.delete({
          url:
            this.$store.state.domain +
            "liveClasses/" +
            id +
            "?db=" +
            this.$store.state.userData.db +
            "&cId=" +
            this.$store.state.userData.cId,
          message: "Live Class deleted successfully.",
          context: this,
          body: null,
          token: this.$store.state.userData.token,
        });
        if (status) this.LoadItems();
      }
    },

    async LoadItems() {
      this.itemLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "liveClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.itemLoading = false;
    },
    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "myclasses/LoadClasses?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dID=0",
        token: this.$store.state.userData.token,
      };
      this.classes = await this.get(obj);
      this.classesOptions = this.classes.map((el) => el.cls);

      var obj2 = {
        url:
          this.$store.state.domain +
          "Subjects?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.allSubjects = await this.get(obj2);

      var obj3 = {
        url:
          this.$store.state.domain +
          "staff/filter?db=" +
          this.$store.state.userData.db +
          "&cId=" +
          this.$store.state.userData.cId +
          "&dptID=0&status=present",
        token: this.$store.state.userData.token,
      };
      this.staffList = await this.get(obj3);

      this.dataLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
<style scoped>
.card {
  transition: 0.4s;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
.set-aspect {
  /* aspect-ratio: 16/9; */
  height: 85vh;
}
</style>
